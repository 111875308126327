var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VLayout',{class:['week-header header--wrap', { 'print-mode': _vm.getIsPrintMode }]},[(!_vm.isForemanColumnHidden)?_c('VFlex',{staticClass:"header-cell crew-header cal-cell key-cell shrink",style:(_vm.standarCellFlexBasis)},[(!_vm.getIsPrintMode)?_c('span',[_vm._v(_vm._s(_vm.currentDateRange))]):_vm._e()]):_vm._e(),_vm._l((_vm.headerDates),function(headerObj,i){return _c('VFlex',{key:headerObj.title,class:[
      'header-cell cal-cell',
      {
        'weekend-header':
          headerObj.title.indexOf('Sat') !== -1 ||
          headerObj.title.indexOf('Sun') !== -1,
      },
      { today: headerObj.title == _vm.today },
      { 'is-the-past': headerObj.isThePast },
      { 'last-cell': i === _vm.numVisibleDates - 1 },
    ],style:(_vm.calCellFlexBasisStyle(headerObj))},[_c('RouterLink',{staticClass:"header-cell-link",attrs:{"to":`/dispatcher/${_vm.dateLink(i)}`}},[_vm._v(" "+_vm._s(headerObj.title)+" ")])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }