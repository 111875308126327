<template>
  <VLayout
    :class="['week-header header--wrap', { 'print-mode': getIsPrintMode }]"
  >
    <VFlex
      v-if="!isForemanColumnHidden"
      class="header-cell crew-header cal-cell key-cell shrink"
      :style="standarCellFlexBasis"
    >
      <span v-if="!getIsPrintMode">{{ currentDateRange }}</span>
    </VFlex>
    <VFlex
      v-for="(headerObj, i) in headerDates"
      :key="headerObj.title"
      :class="[
        'header-cell cal-cell',
        {
          'weekend-header':
            headerObj.title.indexOf('Sat') !== -1 ||
            headerObj.title.indexOf('Sun') !== -1,
        },
        { today: headerObj.title == today },
        { 'is-the-past': headerObj.isThePast },
        { 'last-cell': i === numVisibleDates - 1 },
      ]"
      :style="calCellFlexBasisStyle(headerObj)"
    >
      <RouterLink :to="`/dispatcher/${dateLink(i)}`" class="header-cell-link">
        {{ headerObj.title }}
      </RouterLink>
    </VFlex>
  </VLayout>
</template>

<script>
import dayjs from '@utils/dayjsCustomParse.js'
import { mapGetters } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getSchedulerField',
  mutationType: 'updateSchedulerField',
})
export default {
  name: 'WeekHeader',
  props: {
    startDate: {
      type: String,
      required: true,
    },
    currentDateRange: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      today: dayjs().format('ddd\nMM/DD'),
    }
  },
  computed: {
    ...mapGetters(['getIsPrintMode']),
    ...mapFields(['isForemanColumnHidden']),
    numVisibleDates() {
      return this.getIsPrintMode ? 14 : 8
    },
    headerDates() {
      let headerDates = []
      let isLargeDeviceOrPrinting =
        ['lg', 'xl'].includes(this.$mq) || this.getIsPrintMode
      for (var i = 0; i < this.numVisibleDates; i++) {
        let loopDate = dayjs(this.startDate, 'MM-DD-YYYY').add(i, 'day')
        let day = loopDate.format('dd')[0]
        let titleFormat = isLargeDeviceOrPrinting ? 'ddd\nM/D' : `M/D`
        let title = isLargeDeviceOrPrinting
          ? loopDate.format(titleFormat)
          : `${day}:${loopDate.format(titleFormat)}`
        let isThePast = loopDate.isBefore(dayjs().format('MM-DD-YYYY'))
        headerDates.push({ title, isThePast })
      }
      return headerDates
    }, // headerDates
    numSundays() {
      const start = dayjs(this.startDate)
      let numSundays = 0
      for (let i = 0; i < this.numVisibleDates + 1; i++) {
        const currentDate = start.add(i, 'day')
        if (currentDate.day() === 0) {
          // Day.js uses 0 for Sunday
          numSundays++
        }
      }
      return numSundays
    }, // numSundays

    standarCellFlexBasis() {
      const numCellsAcross =
        this.numVisibleDates + (this.isForemanColumnHidden ? 0 : 1)
      const numKeyCells = 1
      const numStandardHeaderCells =
        numCellsAcross - this.numSundays - numKeyCells

      const keyCellWidth = 80
      const nonStandardCellWidthInPx = this.numSundays * 50 + keyCellWidth
      const widthToSubtractForEachStandardCell =
        nonStandardCellWidthInPx / numStandardHeaderCells

      const flexBasisPercentage = 100 / numStandardHeaderCells
      const flexBasis = `calc(${flexBasisPercentage}% - ${widthToSubtractForEachStandardCell}px)`

      return { 'flex-basis': flexBasis }
    },
  }, // computed
  methods: {
    dateLink(index) {
      return dayjs(this.startDate)
        .add(index, 'day')
        .format('MM-DD-YYYY')
    }, // dateLink
    calCellFlexBasisStyle(headerObj) {
      let isSunday = headerObj.title.indexOf('Sun') !== -1

      return isSunday ? `flex-basis:50px;` : this.standarCellFlexBasis
    },
  },
}
</script>

<style lang="scss" scoped>
.week-header {
}
.header-cell {
  font-size: 0.9em;
  font-weight: bold;
  background-color: white;
  border-width: 1px;
  &:not(:last-child) {
    border-right-width: 0;
  }

  &:not(.crew-header) {
    padding: 0;
  }

  .header-cell-link {
    padding: 10px 10px;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    &:link,
    &:visited {
      color: $gray-dark;
    }
    &:hover {
      background-color: darken(white, 8%);
    }

    @media print {
      font-size: 0.8em;
    }
  }

  &.weekend-header {
    background-color: lighten(black, 85%);
    border-color: lighten(black, 75%);
  }
  &.today {
    font-weight: bold;
    border-bottom-color: #d32f2f;
    a.header-cell-link {
      &:link,
      &:visited {
        color: #d32f2f;
      }
    }
  }
  &.is-the-past {
    $val: 50%;

    background-color: lighten(black, $val);
    border-color: lighten(black, $val - 10);
    .header-cell-link {
      &:link,
      &:visited {
        color: darken(white, 10);
      }
      &:hover {
        background-color: lighten(black, ($val - 10));
      }
    }

    &.weekend-header {
      background-color: lighten(black, $val - 7);
      border-color: lighten(black, $val - 15);
      .header-cell-link {
        &:hover {
          background-color: lighten(black, ($val - 18));
        }
      }
    }
  }

  &.key-cell {
    display: flex;
    align-content: center;
    padding: 0 10px;
    font-size: 1.1rem;
    color: white;
    background-color: #9e9e9e;
    border-color: #9e9e9e;
    max-width: 80px;
  }
  &.last-cell {
    border-right: 1px solid lighten(black, 75%);
  }
}
.print-mode .header-cell {
  $w: 100% / 15;

  flex-basis: $w;
}
</style>
